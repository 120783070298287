:root {
  font-size: 16px;
}

::-webkit-scrollbar {
  width: .5rem;
}

::-webkit-scrollbar-track {
  background-color: #0000001a;
  border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
  background-color: #0003;
  border-radius: 1rem;
}

* {
  box-sizing: border-box;
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
}

.B2wDDG_flagsContainer, .B2wDDG_countriesContainer {
  grid-template-rows: repeat(3, auto);
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  display: grid;
}

@media (width >= 768px) {
  .B2wDDG_flagsContainer, .B2wDDG_countriesContainer {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: repeat(3, 1fr);
  }
}

.B2wDDG_country {
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 4.5rem;
  display: flex;
}

.B2wDDG_countryActionArea {
  padding: 1rem 2rem !important;
}

.B2wDDG_countryActionArea h6 {
  line-height: 1;
}

h1.B2wDDG_title {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 3rem;
  font-weight: 700;
}

h2.B2wDDG_flagName, h2.B2wDDG_subtitle {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

.B2wDDG_controls {
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  display: flex;
}

.B2wDDG_clickable {
  cursor: pointer;
}

.B2wDDG_flag {
  position: relative;
}

.B2wDDG_flagName {
  text-align: center;
}

.B2wDDG_clickedFlagText {
  text-align: center;
  background-color: #ffffffbf;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.B2wDDG_clickedFlagText h6 {
  line-height: 1 !important;
}

.B2wDDG_correctAnswer, .B2wDDG_incorrectAnswer {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.B2wDDG_correctAnswer svg, .B2wDDG_incorrectAnswer svg {
  width: 2rem;
  height: 2rem;
}

.B2wDDG_correctAnswer {
  color: green;
}

.B2wDDG_incorrectAnswer {
  color: red;
}

.B2wDDG_gamesNav {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.B2wDDG_appBar {
  margin-bottom: 2rem;
}

.B2wDDG_singleFlagContainer {
  justify-content: center;
  align-items: center;
  width: 50%;
  max-width: 600px;
  margin: auto auto 1rem;
  display: flex;
}

.B2wDDG_singleFlagContainer img {
  width: 100%;
  max-width: 600px;
}

@media (width >= 768px) {
  .B2wDDG_singleFlagContainer {
    width: 100%;
  }
}

/*# sourceMappingURL=index.84b03cbf.css.map */
