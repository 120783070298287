:root {
    font-size: 16px;
}

::-webkit-scrollbar {
    width: 0.5rem;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 1rem;
}

* {
    box-sizing: border-box;
}

body {
    font-size: 1rem;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
    color: #000;
    margin: 0;
    padding: 0;
}